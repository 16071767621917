.react-datepicker__month-container {
  /* background-color: #081833; */
  color: #969eac;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
}

.react-datepicker__month {
  /* padding-top: 1rem; */
}

.react-datepicker__week {
  color: #c8d2e3;
}

.react-datepicker__month::selection {
  padding: 1rem 0;
  color: black;
}

.react-datepicker__current-month {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 1rem;
  padding: 0.2rem;
}

.react-datepicker__day {
  padding: 5px;

  font-family: "Fira Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #364250;
}

.react-datepicker__day--selected {
  background-color: #2e496a;
  border-radius: 8px;

  display: inline-block;
  /* padding-bottom: 10px; */
  position: relative;
  text-align: center;
  padding: 15px 5px 15px 5px;
  color: #ffffff;
  /* min-width: 100px; */
}

.react-datepicker__day--selected::before {
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: #c8d2e3;
  bottom: 10px;
  height: 4px;
  content: "";
  width: 4px;
  left: 50%;
}

.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range {
  background-color: #2e496a;
  border-radius: 8px;

  display: inline-block;
  /* padding-bottom: 10px; */
  position: relative;
  text-align: center;
  padding: 15px 5px 15px 5px;
  color: #ffffff;
  /* min-width: 100px; */
}

/* .react-datepicker__day:hover {
  padding-b: 0px 0px 5px 0px;
} */

.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--in-range::before {
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: #c8d2e3;
  bottom: 10px;
  height: 4px;
  content: "";
  width: 4px;
  left: 50%;
}

.react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range {
  background-color: #2e496a;
  border-radius: 8px;

  display: inline-block;
  /* padding-bottom: 10px; */
  position: relative;
  text-align: center;
  padding: 15px 5px 15px 5px;
  color: #ffffff;
  /* min-width: 100px; */
}

.react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range::before {
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: #c8d2e3;
  bottom: 10px;
  height: 4px;
  content: "";
  width: 4px;
  left: 50%;
}

.react-datepicker__day-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 150%;
  color: #c8d2e3;
}

.react-datepicker__day.react-datepicker__day--in-range {
  border-radius: 8px;
  background: rgba(46, 73, 106, 0.2);
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 15px 5px 15px 5px;
  color: #09101d;
}

.react-datepicker__day.react-datepicker__day--in-range::before {
  background: rgba(46, 73, 106, 0.5);
  content: "";
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  height: 4px;
  content: "";
  width: 4px;
  left: 50%;
}

.react-datepicker__month-text:hover {
  background-color: #534cea;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url("../../assets/arrow drop_down_new.png");
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.react-datepicker__day--disabled {
  color: #cccc;
}

select.react-datepicker__month-select {
  padding-right: 25px;
  background-image: url("../../assets/down-s-line.png");
}

select.react-datepicker__year-select {
  padding-right: 25px;
  background-image: url("../../assets/down-s-line.png");
}

/* .react-datepicker__input-container {
  background-image: url("../../assets/calendar today.png");
  background-repeat: no-repeat;
  background-position: 95% 50%;
  margin-top: 0.75rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(133, 140, 148, var(--tw-border-opacity));
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;
}

@media (min-width: 300px) and (max-width: 768px) {
  .react-datepicker__input-container input {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .react-datepicker__input-container input {
    width: 91.666667%;
  }
}

@media (min-width: 1024px) {
  .react-datepicker__input-container input {
    width: 91.666667%;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .react-datepicker__input-container {
    width: 40vw;
  }
}

@media (min-width: 768px) {
  .react-datepicker__input-container {
    width: 91.666667%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .react-datepicker__input-container {
    width: 16vw;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .react-datepicker__input-container {
    width: 21vw;
  }
} */
